#app {
  main {
    overflow: hidden;
    min-height: 100vh;

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}
