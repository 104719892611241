html {
  font-size: $base-font-size;

  @include media-breakpoint-up($desktop-breakpoint) {
    font-size: $base-desktop-font-size;
  }
}

button, [type=button], [type=reset], [type=submit] {
  appearance: none;
}

p,
ul,
ol,
dl {
  &:last-child {
    margin-bottom: 0;
  }
}

img {
  display: block;
  margin-bottom: $paragraph-margin-bottom;
  max-width: 100%;
  height: auto;

  &:last-child {
    margin-bottom: 0;
  }

  &.profile-image {
    border: 3px solid #fff;
    border-radius: 50%;
    padding: 0.25rem;
  }
}

hr {
  &.bold {
    border-top-width: 2px;
  }
}