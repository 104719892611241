
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.suppress-scrollbar {
  body {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
  }
}
