$btn-list-btn-margin: $spacer * 0.2 !default;

.btn-list,
.button-list {
  margin: $btn-list-btn-margin * -1;

  .btn,
  .button,
  button {
    margin: $btn-list-btn-margin;

    &.btn-block {
      width: calc(100% - ($btn-list-btn-margin * 2));
    }
  }
}
