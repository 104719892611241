// Brandon
@font-face {
  font-family: 'Brandon';
  src: url('#{$publicPath}fonts/brandon-thin.woff2') format('woff2'),
       url('#{$publicPath}fonts/brandon-thin.woff') format('woff');
  font-weight: 250;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon';
  src: url('#{$publicPath}fonts/brandon-thin-italic.woff2') format('woff2'),
       url('#{$publicPath}fonts/brandon-thin-italic.woff') format('woff');
  font-weight: 250;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon';
  src: url('#{$publicPath}fonts/brandon-light.woff2') format('woff2'),
       url('#{$publicPath}fonts/brandon-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon';
  src: url('#{$publicPath}fonts/brandon-light-italic.woff2') format('woff2'),
       url('#{$publicPath}fonts/brandon-light-italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon';
  src: url('#{$publicPath}fonts/brandon-regular.woff2') format('woff2'),
       url('#{$publicPath}fonts/brandon-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon';
  src: url('#{$publicPath}fonts/brandon-regular-italic.woff2') format('woff2'),
       url('#{$publicPath}fonts/brandon-regular-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon';
  src: url('#{$publicPath}fonts/brandon-medium.woff2') format('woff2'),
       url('#{$publicPath}fonts/brandon-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon';
  src: url('#{$publicPath}fonts/brandon-medium-italic.woff2') format('woff2'),
       url('#{$publicPath}fonts/brandon-medium-italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon';
  src: url('#{$publicPath}fonts/brandon-bold.woff2') format('woff2'),
       url('#{$publicPath}fonts/brandon-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon';
  src: url('#{$publicPath}fonts/brandon-bold-italic.woff2') format('woff2'),
       url('#{$publicPath}fonts/brandon-bold-italic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon';
  src: url('#{$publicPath}fonts/brandon-black.woff2') format('woff2'),
       url('#{$publicPath}fonts/brandon-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon';
  src: url('#{$publicPath}fonts/brandon-black-italic.woff2') format('woff2'),
       url('#{$publicPath}fonts/brandon-black-italic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

// Quickpen
@font-face {
  font-family: 'Quickpen';
  src: url('#{$publicPath}fonts/quickpen-regular.woff2') format('woff2'),
       url('#{$publicPath}fonts/quickpen-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quickpen';
  src: url('#{$publicPath}fonts/quickpen-bold.woff2') format('woff2'),
       url('#{$publicPath}fonts/quickpen-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
