.category-cloud {
  margin-bottom: $component-padding-y;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-weight: $font-weight-bold;
    font-size: inherit;
  }

  .category-cloud-container {
    // reset list-style-type
    @include list-unstyled();
    list-style-position: unset;
    padding-left: 0;
    > li {
      line-height: inherit;
      padding-left: 0;
      margin-left: 0;

      &::marker {
        content: none;
        display: none;
      }
    }

    .category-cloud-item {
      display: inline-block;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.5;
      border: 1px solid $primary;
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;
      transition:
        background-color 0.3s,
        color 0.3s;

      &:hover {
        color: $primary;
        background-color: $primary;

        > a {
          color: $white;
        }
      }

      > a {
        text-decoration: none;
      }
    }
  }
}
