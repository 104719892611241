
// mobile
$footernavigation-font-size: $font-size-sm !default;

$footernavigation-item-padding-y: $spacer * 0.25 !default;
$footernavigation-item-padding-x: 0 !default;

// desktop
$footernavigation-breakpoint: $desktop-breakpoint !default;

$footernavigation-desktop-font-size: $footernavigation-font-size !default;

$footernavigation-desktop-item-padding-y: 0 !default;
$footernavigation-desktop-item-padding-x: $spacer * 0.75 !default;

.footer-navigation {
  margin: ($footernavigation-item-padding-y * -1) ($footernavigation-item-padding-x * -1);
  font-size: $footernavigation-font-size;

  a,
  span {
    display: block;
    padding: $footernavigation-item-padding-y $footernavigation-item-padding-x;
  }

  @include media-breakpoint-up($footernavigation-breakpoint) {
    display: flex;
    margin: ($footernavigation-desktop-item-padding-y * -1) ($footernavigation-desktop-item-padding-x * -1);
    font-size: $footernavigation-desktop-font-size;

    a,
    span {
      padding: $footernavigation-desktop-item-padding-y $footernavigation-desktop-item-padding-x;
    }
  }
}