$descriptionlist-title-font-weight: $font-weight-medium !default;
$descriptionlist-title-font-size: $font-size-sm !default;

$descriptionlist-data-gap: $spacer * 0.5 !default;
$descriptionlist-data-font-weight: inherit !default;
$descriptionlist-data-font-size: inherit !default;

dl {
  > dt {
    font-weight: $descriptionlist-title-font-weight;
    font-size: $descriptionlist-title-font-size;
  }

  > dd {
    margin-bottom: $descriptionlist-data-gap;
    font-weight: $descriptionlist-data-font-weight;
    font-size: $descriptionlist-data-font-size;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
