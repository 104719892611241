$blockquote-padding-y: 0 !default;
$blockquote-padding-x: 0 !default;

$blockquote-border-width: 40% !default;
$blockquote-border-size: $border-width !default;
$blockquote-border-color: $border-color !default;

$blockquote-quote-start: '«' !default;
$blockquote-quote-end: '»' !default;
$blockquote-font-family: inherit !default;
$blockquote-font-size: inherit !default;
$blockquote-font-weight: inherit !default;
$blockquote-font-style: inherit !default;
$blockquote-line-height: inherit !default;
$blockquote-text-align: center !default;

$blockquote-cite-gap: $spacer !default;
$blockquote-cite-font-family: inherit !default;
$blockquote-cite-font-size: inherit !default;
$blockquote-cite-font-weight: inherit !default;
$blockquote-cite-font-style: inherit !default;

blockquote {
  padding: $blockquote-padding-y $blockquote-padding-x;
  font-family: $blockquote-font-family;
  font-size: $blockquote-font-size;
  font-weight: $blockquote-font-weight;
  font-style: $blockquote-font-style;
  line-height: $blockquote-line-height;
  text-align: $blockquote-text-align;

  &.blockquote {
    padding-top: 0;
    padding-bottom: 0;

    &:before,
    &:after {
      display: block;
      content: '';
      margin: auto;
      width: $blockquote-border-width;
      height: $blockquote-border-size;
      background-color: $blockquote-border-color;
    }

    &:before {
      margin-bottom: $blockquote-padding-y;
    }

    &:after {
      margin-top: $blockquote-padding-y;
    }

    .blockquote-quoted {
      &:before {
        content: $blockquote-quote-start;
      }

      &:after {
        content: $blockquote-quote-end;
      }
    }
  }

  cite {
    display: block;
    margin-top: $blockquote-cite-gap;
    font-family: $blockquote-cite-font-family;
    font-size: $blockquote-cite-font-size;
    font-weight: $blockquote-cite-font-weight;
    font-style: $blockquote-cite-font-style;
  }
}
