.component {
  @include component();
}

.component-y {
  @include component('y');
}

.component-x {
  @include component('x');
}
