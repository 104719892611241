.font-sansserif { font-family: $font-family-sans-serif; }
.font-monospace { font-family: $font-family-monospace; }
.font-handwritten { font-family: $font-family-handwritten; }

.font-sm { font-size: $font-size-sm; }
.font-md { font-size: $font-size-base; }
.font-lg { font-size: $font-size-lg; }

.font-thin { font-weight: $font-weight-thin; }
.font-light { font-weight: $font-weight-light; }
.font-regular { font-weight: $font-weight-base; }
.font-medium { font-weight: $font-weight-medium; }
.font-bold { font-weight: $font-weight-bold; }
.font-black { font-weight: $font-weight-black; }

.font-normal { font-style: normal; }
.font-italic { font-style: italic; }

.font-spacing-1 { letter-spacing: $letter-spacing-1; }
.font-spacing-2 { letter-spacing: $letter-spacing-2; }
.font-spacing-3 { letter-spacing: $letter-spacing-3; }

.text-nowrap { white-space: nowrap; }
.text-wrap { white-space: normal; }

.min-vh-75 {
  min-height: 75vh !important;
}

body {
  .link-inherit,
  a.link-inherit {
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    color: inherit;
    text-decoration: none;
    
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

@each $key, $value in $grid-breakpoints {
  @include media-breakpoint-up($key) {
    .font-sansserif-#{$key} { font-family: $font-family-sans-serif; }
    .font-monospace-#{$key} { font-family: $font-family-monospace; }
    .font-handwritten-#{$key} { font-family: $font-family-handwritten; }

    .font-sm-#{$key} { font-size: $font-size-sm; }
    .font-md-#{$key} { font-size: $font-size-base; }
    .font-lg-#{$key} { font-size: $font-size-lg; }

    .font-thin-#{$key} { font-weight: $font-weight-thin; }
    .font-light-#{$key} { font-weight: $font-weight-light; }
    .font-regular-#{$key} { font-weight: $font-weight-base; }
    .font-medium-#{$key} { font-weight: $font-weight-medium; }
    .font-bold-#{$key} { font-weight: $font-weight-bold; }
    .font-black-#{$key} { font-weight: $font-weight-black; }

    .font-normal-#{$key} { font-style: normal; }
    .font-italic-#{$key} { font-style: italic; }

    .font-spacing-1-#{$key} { letter-spacing: $letter-spacing-1; }
    .font-spacing-2-#{$key} { letter-spacing: $letter-spacing-2; }
    .font-spacing-3-#{$key} { letter-spacing: $letter-spacing-3; }

    .text-nowrap-#{$key} { white-space: nowrap; }
    .text-wrap-#{$key} { white-space: normal; }
  }
}

.seo {
  @include sr-only();

  line-height: 0;
}
