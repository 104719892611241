.eyecatcher-stack {
  display: inline-flex;
  flex-direction: column;

  .eyecatcher {
    + .eyecatcher {
      margin-top: -15%;
    }
  }

  &.overlay {
    position: absolute;
    top: $spacer;
    right: $spacer;

    &.top { top: $spacer; }
    &.bottom { bottom: $spacer; }
    &.left { left: $spacer; }
    &.right { right: $spacer; }
  }
}
