html {
   overflow-y: scroll;

   body {
     overflow-x: hidden;
     overflow-y: hidden;
   }

   &.ready {
    overflow-y: inherit;

    body {
      overflow-y: scroll;
    }
   }
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > header {
    flex: 0 1 0%;
  }

  > main {
    flex: 1 0 100%;
  }

  > footer {
    flex: 0 1 0%;
  }
}

body.layout-minimal {
  background-color: $layout-minimal-bg;

  #app {
    .container {
      @include make-container-max-widths($layout-minimal-container-widths);
    }

    > .minimal-container {
      @include make-container();
      @include make-container-max-widths($layout-minimal-container-widths);
      flex: 1 0 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: $layout-minimal-gap;

      > .minimal-content {
        @include component('both', false);
        width: 100%;
        background-color: $layout-minimal-content-bg;
        box-shadow: $layout-minimal-content-box-shadow;

        > header {
          margin-bottom: $layout-minimal-content-header-gap;
        }
      }
    }
  }
}
