// mobile
$indentedtext-margin-y: $component-padding-y !default;
$indentedtext-margin-x: 0 !default;

// desktop
$indentedtext-breakpoint: $desktop-breakpoint !default;

$indentedtext-desktop-margin-y: $component-desktop-padding-y !default;
$indentedtext-desktop-margin-x: 0 !default;

.indented-text {
  margin: $indentedtext-margin-y $indentedtext-margin-x;
  @include indented-textelements();

  @include media-breakpoint-up($indentedtext-breakpoint) {
    margin: $indentedtext-desktop-margin-y $indentedtext-desktop-margin-x;
  }
}
