.contentblock,
.indented-text,
.search-introcontent,
.search-outrocontent {
    ul {
        list-style-position: outside;

        padding-left: 0;

        > li {
            line-height: 1.9;
            padding-left: 1em;
            margin-left: 0.3em;

            &::marker {
                content: "•";
                font: menu;
            }
        }
    }
}