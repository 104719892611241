// mobile
$metanavigation-item-padding-y: $spacer * 0.5 !default;
$metanavigation-item-padding-x: 0 !default;
$metanavigation-item-border: map-get($borders, "base") !default;
$metanavigation-item-font-size: $font-size-sm !default;
$metanavigation-item-font-weight: $font-weight-medium !default;
$metanavigation-item-color: #656565 !default;
$metanavigation-item-letter-spacing: 0 !default;

// desktop
$metanavigation-breakpoint: $desktop-breakpoint !default;

$metanavigation-desktop-gap: $spacer * 0.5 !default;

$metanavigation-desktop-item-gutter: $spacer * 1.2 !default;
$metanavigation-desktop-item-border: set-nth(map-get($borders, "bold"), 3, $primary) !default;
$metanavigation-desktop-item-font-size: $font-size-sm !default;
$metanavigation-desktop-item-font-weight: $metanavigation-item-font-weight !default;
$metanavigation-desktop-item-color: $metanavigation-item-color !default;
$metanavigation-desktop-item-letter-spacing: $metanavigation-desktop-item-font-size * 0.03 !default;

.metanavigation {
  height: 1.8125rem;

  @include media-breakpoint-down(get-breakpoint-key($metanavigation-breakpoint, -1)) {
    display: none;

    .header-menu & {
      display: block;
    }

    > a,
    > .languagenavigation .dropdown-toggle {
      display: block;
      padding: $metanavigation-item-padding-y $metanavigation-item-padding-x;
      border-bottom: $metanavigation-item-border;
      font-size: $metanavigation-item-font-size;
      font-weight: $metanavigation-item-font-weight;
      color: $metanavigation-item-color;
      letter-spacing: $metanavigation-item-letter-spacing;
      text-decoration: none;
      border-left: none;
      border-right: none;
    }
  }

  @include media-breakpoint-up($metanavigation-breakpoint) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $metanavigation-desktop-gap ($metanavigation-desktop-item-gutter * -0.5) 0;
    height: 29px;

    > .languagenavigation .dropdown-toggle {
      background-image: escape-svg(
        str-replace($dropdown-toggle-icon, "fill='#{$dropdown-toggle-color}'", "fill='#{$metanavigation-item-color}'")
      );
    }

    .header-menu & {
      display: none;
    }

    > a,
    > .languagenavigation .dropdown-toggle {
      margin-left: $metanavigation-desktop-item-gutter * 0.5;
      margin-right: $metanavigation-desktop-item-gutter * 0.5;
      font-size: $metanavigation-desktop-item-font-size;
      font-weight: $metanavigation-desktop-item-font-weight;
      color: $metanavigation-desktop-item-color;
      letter-spacing: $metanavigation-desktop-item-letter-spacing;
      text-decoration: none;
    }

    > a {
      border-bottom: nth($metanavigation-desktop-item-border, 1) solid transparent;

      &:hover,
      &.active {
        border-bottom: $metanavigation-desktop-item-border;
        font-weight: $font-weight-medium;
        color: $body-color;
      }
    }

    > .languagenavigation .dropdown-toggle {
      margin-left: 0;
    }
  }
}
