@include control-size(null, $input-padding-y, $input-padding-x, $input-height, $input-border-radius, $input-font-size, $input-line-height);
@include control-size(xs, $input-padding-y-xs, $input-padding-x-xs, $input-height-xs, $input-border-radius-xs, $input-font-size-xs, $input-line-height-xs);
@include control-size(sm, $input-padding-y-sm, $input-padding-x-sm, $input-height-sm, $input-border-radius-sm, $input-font-size-sm, $input-line-height-sm);
@include control-size(lg, $input-padding-y-lg, $input-padding-x-lg, $input-height-lg, $input-border-radius-lg, $input-font-size-lg, $input-line-height-lg);

.custom-select {
  option:disabled {
    background: $input-disabled-bg;
    color: color-yiq($input-disabled-bg);
  }
}

.custom-control {
  .custom-control-input {
    ~ .custom-control-label {
      margin-bottom: $custom-control-gutter;
    }

    .is-valid &,
    .is-invalid &,
    &.is-valid,
    &.is-invalid {
      ~ .custom-control-label {
        color: $input-color;
      }
    }

    .is-valid &,
    &.is-valid {
      ~ .custom-control-label {
        &:before {
          background-color: $custom-control-indicator-bg;
          border-color: $form-feedback-valid-color;
        }
      }
    }

    .is-invalid &,
    &.is-invalid {
      ~ .custom-control-label {
        &:before {
          border-color: $form-feedback-invalid-color;
        }
      }
    }

    &:disabled,
    &.disabled {
      ~ .custom-control-label {
        opacity: $custom-control-disabled-opacity;
      }
    }
  }

  &:last-child {
    .custom-control-input {
      ~ .custom-control-label {
        margin-bottom: 0;
      }
    }
  }

  &.custom-switch {
    .custom-control-input {
      ~ .custom-control-label {
        &:before {
          background-color: $custom-switch-bg;
        }
      }

      &:checked {
        ~ .custom-control-label {
          &:before {
            background-color: $custom-switch-on-bg;
            border-color: $custom-switch-on-border-color;
          }
        }
      }
    }
  }
}

.b-form-btn-label-control.form-control > .form-control {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.b-form-datepicker.form-control {
  .form-control {
    padding-right: 0!important;
    background-image: none!important;
  }

  &.is-valid {
    background-image: escape-svg($form-feedback-icon-valid);
  }

  &.is-invalid {
    background-image: escape-svg($form-feedback-icon-invalid);
  }
}

.b-form-spinbutton {
  background-image: none!important;

  output {
    bdi {
      width: 100%;
    }
  }
}

.form-group {
  > label {
    color: $label-color;
  }

  &.is-invalid {
    > label {
      color: $form-feedback-invalid-color;
    }
  }
}

.input-group {
  .input-group-prepend {
    .input-group-text {
      &:last-child {
        padding-right: 0;
      }
    }
  }

  .input-group-append {
    .input-group-text {
      &:first-child {
        padding-left: 0;
      }
    }
  }

  .form-control,
  .custom-select {
    &:not(:first-child) {
      border-left: 0 none;
    }

    &:not(:last-child) {
      border-right: 0 none;
    }
  }

  .custom-file {
    .custom-file-label {
      border-radius: inherit;

      &:after {
        border-left: $custom-file-border-width solid $custom-file-border-color;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
      }
    }

    &:not(:first-child) {
      .custom-file-label {
        border-left: 0 none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &:not(:last-child) {
      .custom-file-label {
        border-right: 0 none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

input,
select,
textarea {
  &:-webkit-autofill {
    &,
    &:hover,
    &:focus {
      -webkit-box-shadow: 0 0 0 1000px theme-color-level('primary', -11) inset;
    }
  }
}
