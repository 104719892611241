.horizontal-scroller {
  position: relative;

  .btn-scroll-left,
  .btn-scroll-right {
    display: none;
  }

  @include media-breakpoint-up($tablet-breakpoint) {
    .scroll-target {
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .btn-scroll-left,
    .btn-scroll-right {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 3;
      margin: 0;
      padding: 0;
      width: $spacer * 3;
      border: none;
      border-radius: 0;
      opacity: 0;
      box-shadow: none;
      transition: opacity ease-in 300ms;

      .bi {
        display: block;
        font-size: $spacer;
        color: $black;
      }

      &.active,
      &.disabled {
        opacity: 0;
      }

      &.active {
        opacity: 1;
      }
    }

    .btn-scroll-left {
      background: linear-gradient(to left, rgba($white, 0) 0%, rgba($white, 1) 50%);
      left: 0;

      &:hover {
        background: linear-gradient(to left, rgba($gray-100, 0) 0%, rgba($gray-100, 1) 50%);
      }
    }

    .btn-scroll-right {
      background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 50%);
      right: 0;

      &:hover {
        background: linear-gradient(to right, rgba($gray-100, 0) 0%, rgba($gray-100, 1) 50%);
      }
    }

    &.scroller-extended-width {
      .btn-scroll-left {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: $spacer * -1.5;
          width: $spacer * 1.5;
          background: $white;
        }
      }

      .btn-scroll-right {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: $spacer * -1.5;
          width: $spacer * 1.5;
          background: $white;
        }
      }
    }

    &:hover {
      .btn-scroll-left,
      .btn-scroll-right {
        opacity: 1;
        transition: opacity ease-out 300ms;

        &.disabled {
          opacity: 0;
        }
      }
    }

    .is-touch & {
      opacity: 1;
    }
  }
}
