.dropdown {
  .dropdown-toggle {
    &:after { display: none!important; }

    &:not(.dropdown-toggle-no-caret) {
      background-repeat: no-repeat;
      background-position: center center;
      background-image: escape-svg($dropdown-toggle-icon);
      background-size: $dropdown-toggle-icon-size;

      &:not(.dropdown-toggle-split) {
        padding-right: calc($btn-padding-x + $dropdown-toggle-icon-size);
        background-position: calc(100% - $btn-padding-x + ($dropdown-toggle-icon-size * 0.5)) center;

        &.btn-xs {
          padding-right: calc($btn-padding-x-xs + $dropdown-toggle-icon-size);
          background-position: calc(100% - $btn-padding-x-xs + ($dropdown-toggle-icon-size * 0.5)) center;
        }

        &.btn-sm {
          padding-right: calc($btn-padding-x-sm + $dropdown-toggle-icon-size);
          background-position: calc(100% - $btn-padding-x-sm + ($dropdown-toggle-icon-size * 0.5)) center;
        }

        &.btn-lg {
          padding-right: calc($btn-padding-x-lg + $dropdown-toggle-icon-size);
          background-position: calc(100% - $btn-padding-x-lg + ($dropdown-toggle-icon-size * 0.5)) center;
        }
      }
    }

    @each $key, $value in $theme-colors {
      &.btn-#{$key} {
        background-image: escape-svg(str-replace($dropdown-toggle-icon, "fill='#{$dropdown-toggle-color}'", "fill='#{color-yiq($value)}'"));
      }
    }
  }

  .dropdown-menu {
    > li {
      &:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;

        > .dropdown-item {
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
        }
      }

      &:last-child {
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;

        > .dropdown-item {
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
        }
      }
    }

    .dropdown-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: calc(#{$dropdown-item-padding-x * 0.5} + #{$dropdown-item-active-gap + 1});

      &.active {
        background-repeat: no-repeat;
        background-position: calc(100% - ($dropdown-item-padding-x * 0.5)) center;
        background-image: escape-svg($dropdown-item-active-icon);
        background-size: $dropdown-item-active-icon-size;

        &:hover {
          color: $dropdown-link-hover-color;
          background-color: $dropdown-link-hover-bg;
        }
      }
    }
  }
}
