// mobile
$herobannercarousel-max-width: 1900px !default;
$herobannercarousel-bg: $beige !default;

$herobannercarousel-carousel-shadow: map-get($shadows, 'herobanner-carousel') !default;
$herobannercarousel-carousel-img-ratios: (
  xs: math.div(1, 1),
  sm: math.div(3, 4),
  md: math.div(3, 5),
  lg: math.div(9, 20),
  xl: math.div(3, 10)
) !default;

// desktop
$herobannercarousel-breakpoint: $desktop-breakpoint !default;

.herobanner-carousel {
  @include fullwidth-bg();
  position: relative;
  left: 50%;
  width: 100vw;
  max-width: $herobannercarousel-max-width;
  background-color: $herobannercarousel-bg;
  transform: translate3d(-50%, 0, 0);

  .carousel {
    .slick-list {
      box-shadow: $herobannercarousel-carousel-shadow;

      .slick-track {
        .slick-slide {
          img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -200vw;
            right: -200vw;
            margin: 0 auto;
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }

  .factbanner {
    background-color: transparent;
  }

  @include media-breakpoint-down(get-breakpoint-key($herobannercarousel-breakpoint, -1)) {
    .factbanner {
      padding-top: 0;
    }
  }

  @each $key, $value in $herobannercarousel-carousel-img-ratios {
    @include media-breakpoint-up($key) {
      .carousel {
        .slick-list {
          .slick-track {
            .slick-slide {
              div[style*='inline-block'] {
                padding-top: 100% * $value;
              }
            }
          }
        }
      }
    }
  }
}