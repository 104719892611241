// mobile
$header-offset: -55px !default;
$header-bg: $body-bg !default;
$header-border: map-get($borders, "header") !default;
$header-shadow: map-get($shadows, "header") !default;
$header-zindex: $zindex-sticky + 5 !default;

// desktop
$header-breakpoint: $desktop-breakpoint !default;

$header-desktop-offset: 0 !default;

#app {
  > header {
    position: sticky;
    top: $header-offset;
    background-color: $header-bg;
    border-bottom: $header-border;
    box-shadow: $header-shadow;
    z-index: $header-zindex;

    // SEO / CLS fix
    min-height: 106.65px;

    @include media-breakpoint-up($header-breakpoint) {
      top: $header-desktop-offset;

      // SEO / CLS fix
      min-height: 123.19px;

      .hide-header-nav & {
        // SEO / CLS fix
        min-height: 88px;
      }

      .search-row {
        height: 48px;
      }
    }
  }
}
