// mobile
$headermenutoggle-gap: $spacer * 0.55 !default;
$headermenutoggle-height: $spacer * 2 !default;

$headermenutoggle-burger-width: $spacer * 1.1 !default;
$headermenutoggle-burger-height: $spacer * 0.7 !default;

$headermenutoggle-burger-patty-height: 1px !default;
$headermenutoggle-burger-patty-bg: currentColor !default;
$headermenutoggle-burger-patty-transition-speed: 300ms !default;

$headermenutoggle-text-gap: $spacer * 0.5 !default;

// desktop
$headermenutoggle-breakpoint: $desktop-breakpoint !default;

.header-menutoggle {
  display: flex;
  align-items: center;
  margin: $headermenutoggle-gap 0;
  padding: 0;
  height: $headermenutoggle-height;
  background: none;
  border: 0 none;
  color: inherit;

  .menutoggle-burger {
    display: block;
    position: relative;
    width: $headermenutoggle-burger-width;
    height: $headermenutoggle-burger-height;

    .burger-patty {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: $headermenutoggle-burger-patty-height;
      background-color: $headermenutoggle-burger-patty-bg;
      transition: all ease-in-out $headermenutoggle-burger-patty-transition-speed;

      &:nth-child(1) {
        top: 0%;
      }

      &:nth-child(2) {
        top: 50%;
        transition-timing-function: ease-out;
        transition-duration: $headermenutoggle-burger-patty-transition-speed * 0.5;
        transition-delay: $headermenutoggle-burger-patty-transition-speed * 0.5;
      }

      &:nth-child(3) {
        top: 100%;
      }
    }

    + .menutoggle-text {
      display: block;
      margin-left: $headermenutoggle-text-gap;
    }
  }

  .menutoggle-text {
  }

  &.is-visible {
    .menutoggle-burger {
      .burger-patty {
        &:nth-child(1) {
          top: 50%;
          transform: scaleX(-1) rotate(-45deg);
        }

        &:nth-child(2) {
          transform: scaleX(0);
          transition-timing-function: ease-in;
          transition-delay: $headermenutoggle-burger-patty-transition-speed * 0;
        }

        &:nth-child(3) {
          top: 50%;
          transform: scaleX(-1) rotate(45deg);
        }
      }
    }
  }

  @include media-breakpoint-up($headermenutoggle-breakpoint) {
    display: none;
  }
}
