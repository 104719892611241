
// mobile
$footercontents-color: $beige !default;

$footercontents-gap: 0 !default;

$footercontents-block-gap: $spacer * 2.5 !default;

$footercontents-block-title-gap: $spacer !default;
$footercontents-block-title-text-transform: uppercase !default;

$footercontents-block-link-icon-gap: $spacer * 0.5 !default;

$footercontents-block-list-border: map-get($borders, 'footer') !default;
$footercontents-block-list-item-padding-y: $spacer * 0.5 !default;
$footercontents-block-list-item-padding-x: 0 !default;

$footercontents-block-list-socialmedia-icon-gap: $spacer * 0.75 !default;
$footercontents-block-list-socialmedia-icon-size: $spacer * 2.75 !default;

$footercontents-block-list-socialmedia-icon-border: map-get($borders, 'footer') !default;
$footercontents-block-list-socialmedia-icon-font-size: $footercontents-block-list-socialmedia-icon-size * 0.45 !default;
$footercontents-block-list-socialmedia-icon-hover-bg: nth($footercontents-block-list-socialmedia-icon-border, 3) !default;

$footercontents-block-list-payment-item-gap: $spacer * 0.75 !default;
$footercontents-block-list-payment-item-img-height: $spacer * 1.6 !default;

// desktop
$footercontents-breakpoint: $desktop-breakpoint !default;

$footercontents-desktop-gap: $spacer * 2 !default;

$footercontents-desktop-block-list-socialmedia-icon-border: $footercontents-block-list-socialmedia-icon-border !default;

$footercontents-desktop-block-list-payment-item-gap: $spacer * 1.25 !default;
$footercontents-desktop-block-list-payment-item-img-height: $spacer * 2 !default;

.footer-contents {
  margin-bottom: $footercontents-gap;

  .content-block {
    margin-bottom: $footercontents-block-gap;

    &.no-gap {
      margin-bottom: 0;

      .bottom-gap {
        margin-bottom: $footercontents-block-gap;
      }
    }

    h6 {
      margin-top: $footercontents-block-title-gap;
      margin-bottom: $footercontents-block-title-gap;
      text-transform: $footercontents-block-title-text-transform;
    }

    a {
      .bi {
        margin-right: $footercontents-block-link-icon-gap;
      }
    }

    .accordion.is-shapeless {
      .accordion-toggle {
        border-bottom: $footercontents-block-list-border !important;

        &.not-collapsed {
          border-bottom: 0 none !important;
        }
      }
    }

    ul {
      @include list-unstyled();
      border-top: $footercontents-block-list-border;

      > li {
        padding: $footercontents-block-list-item-padding-y $footercontents-block-list-item-padding-x;
        border-bottom: $footercontents-block-list-border;
      }

      &.list-socialmedia {
        display: flex;
        flex-wrap: wrap;
        margin: $footercontents-block-list-socialmedia-icon-gap * -0.5;
        border-top: 0 none;

        > li {
          margin: $footercontents-block-list-socialmedia-icon-gap * 0.5;
          padding: 0;
          border-bottom: 0 none;

          > a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $footercontents-block-list-socialmedia-icon-size;
            height: $footercontents-block-list-socialmedia-icon-size;
            border: $footercontents-block-list-socialmedia-icon-border;
            border-radius: 100%;
            font-size: $footercontents-block-list-socialmedia-icon-font-size;

            .bi {
              display: block;
              margin: 0;
            }

            &:hover {
              background-color: $footercontents-block-list-socialmedia-icon-hover-bg;
            }
          }
        }
      }

      &.list-payment {
        display: flex;
        flex-wrap: wrap;
        margin: $footercontents-block-list-payment-item-gap * -0.5;
        border-top: 0 none;

        > li {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: $footercontents-block-list-payment-item-gap * 0.5;
          padding: 0;
          border-bottom: 0 none;

          img {
            display: block;
            margin: 0;
            width: auto;
            height: $footercontents-block-list-payment-item-img-height;
          }
        }
      }
    }
  }

  @include media-breakpoint-up($footercontents-breakpoint) {
    margin-bottom: $footercontents-desktop-gap;

    .content-block {
      ul {
        &.list-socialmedia {
          > li {
            > a {
              border: $footercontents-desktop-block-list-socialmedia-icon-border;
            }
          }
        }

        &.list-payment {
          margin: $footercontents-desktop-block-list-payment-item-gap * -0.5;

          > li {
            margin: $footercontents-desktop-block-list-payment-item-gap * 0.5;

            img {
              height: $footercontents-desktop-block-list-payment-item-img-height;
            }
          }
        }
      }
    }
  }
}