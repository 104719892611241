$link-list-btn-margin-y: $spacer * 0.2 !default;
$link-list-btn-margin-x: $spacer * 0.75 !default;

.link-list {
  margin: ($link-list-btn-margin-y * -1) ($link-list-btn-margin-x * -1);

  a {
    display: inline-block;
    margin: $link-list-btn-margin-y $link-list-btn-margin-x;
  }
}
