.category-hotlinks {
  @include list-unstyled();

  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: show;
  padding: $spacer 0 $spacer 0;
  margin: 0 $spacer * -1.5 0 $spacer * -1.5;
  white-space: nowrap;

  .category-hotlink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 0 $spacer * 1;
    white-space: normal;

    &:first-child {
      padding-left: $spacer * 1.5;
    }

    &:last-child {
      padding-right: $spacer * 1.5;
    }

    a {
      text-decoration: none;
    }

    .hotlink-image {
      width: 33vw;
      height: 33vw;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $gray-300;
      border-radius: 50%;
      overflow: hidden;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    .hotlink-text {
      font-size: 1rem;
      margin-top: $spacer;
      margin-bottom: 0;
      flex-grow: 1;
      width: 100%;
      text-align: center;
    }
  }

  @include media-breakpoint-up($tablet-breakpoint) {
    .category-hotlink {
      &:hover {
        .hotlink-image {
          box-shadow: map-get($shadows, "productteaser");
        }
      }
    }
  }

  @include media-breakpoint-up($tablet-breakpoint) {
    .category-hotlink {
      width: 144px;

      &:first-child {
        width: 168px;
      }

      &:last-child {
        width: 168px;
      }

      .hotlink-image {
        width: 112px;
        height: 112px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .category-hotlink {
      width: 171px;

      &:first-child {
        width: 195px;
      }

      &:last-child {
        width: 195px;
      }

      .hotlink-image {
        width: 139px;
        height: 139px;
      }
    }
  }
}
