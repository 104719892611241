$sociallist-margin-y: $component-padding-y !default;
$sociallist-item-gap: $spacer * 0.5 !default;
$sociallist-item-size: $spacer * 2.5 !default;
$sociallist-item-bg: transparent !default;
$sociallist-item-border: set-nth(map-get($borders, 'bold'), 3, $beige);
$sociallist-item-font-size: $font-size-lg !default;
$sociallist-item-color: inherit !default;

$sociallist-item-hover-bg: nth($sociallist-item-border, 3) !default;

ul.social-list {
  @include list-unstyled();  
  margin: $sociallist-item-gap * -0.5;
  display: flex;
  flex-flow:wrap;
  justify-content: center;  
  margin-bottom: $sociallist-margin-y;

  > li {
    display:inline-block;
    > a {      
      display: flex;
      justify-content: center;
      align-items: center;
      margin: $sociallist-item-gap * 0.5;
      width: $sociallist-item-size;
      height: $sociallist-item-size;
      background-color: $sociallist-item-bg;
      border: $sociallist-item-border;
      font-size: $sociallist-item-font-size;
      color: $sociallist-item-color;
      border-radius: 50%;

      &:hover {
        background-color: $sociallist-item-hover-bg;
      }
    }
  }
}

.popover-body{
  .social-list{
    margin-bottom:0;
    flex-flow:wrap;
    justify-content: left;  
  }
}