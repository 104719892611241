
$herobanner-max-width: 1900px !default;
$herobanner-bg: $beige !default;

$herobanner-content-padding-y: $spacer * 6 !default;
$herobanner-content-padding-x: 0 !default;

$herobanner-img-ratios: (
  xs: math.div(1, 1),
  sm: math.div(3, 4),
  md: math.div(3, 5),
  lg: math.div(9, 20),
  xl: math.div(3, 10)
) !default;

$herobanner-text-shadow: map-get($shadows, 'herobanner-text') !default;

.herobanner {
  @include fullwidth-bg();
  position: relative;
  left: 50%;
  width: 100vw;
  max-width: $herobanner-max-width;
  background-color: $herobanner-bg;
  transform: translate3d(-50%, 0, 0);

  .herobanner-nobg & {
    background-color: transparent;
  }

  > div {
    position: relative;
    overflow: hidden;

    .herobanner-content {
      position: relative;
      padding: $herobanner-content-padding-y $herobanner-content-padding-x;

      .backlink {
        margin-top: $herobanner-content-padding-y * -1;
      }

      .herobanner-text {
        text-align: center;

        &.text-white {
          text-shadow: $herobanner-text-shadow;
        }
      }
    }
  }

  &.has-image {
    .herobanner-content {
      position: absolute;
      inset: 0;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center center;

      .backlink {
        position: relative;
        z-index: 2;
      }

      .herobanner-text {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        height: 100%;
      }
    }

    @each $key, $value in $herobanner-img-ratios {
      @include media-breakpoint-up($key) {
        > div {
          padding-top: 100% * $value;
        }
      }
    }
  }
}