.header-menu {
  // default ul styles
  ul {
    @include list-unstyled();
  }

  @include media-breakpoint-down(get-breakpoint-key($desktop-breakpoint, -1)) {
    // menu-container styling and animation
    .menu-container {
      position: fixed;
      inset: 0;
      overflow: hidden;
      z-index: $zindex-fixed;
      animation: containerOut 0ms 250ms forwards;

      .main-items {
        position: absolute;
        inset: 0;
        overflow: hidden;
        overflow-y: auto;

        padding: $grid-gutter-width * 0.5 $grid-gutter-width * 0.5;
        background-color: $beige;
        box-shadow: inset map-get($shadows, "header");

        transform: translate3d(0, -100%, 0);
        transition: transform 250ms ease-in-out;

        .mobile-nav-open & {
          transform: translate3D(0, 0%, 0);
        }
      }

      .mobile-nav-open & {
        animation: containerIn 0ms forwards;
      }

      @keyframes containerIn {
        0% {
          transform: translate3d(0, -100%, 0);
        }
        100% {
          transform: translate3d(0, 0%, 0);
        }
      }

      @keyframes containerOut {
        0% {
          transform: translate3d(0, -100%, 0);
        }
        100% {
          transform: translate3d(0, -100%, 0);
        }
      }
    }

    // menu-container animation
    .menu-container {
      &.is-right-out {
        transform: translate3d(100%, 0, 0);
      }

      &.is-left-out {
        transform: translate3d(-100%, 0, 0);
      }

      &.slide-in-right {
        animation: slideInRight 0.3s forwards;

        > * {
          overflow: hidden;
        }
      }

      &.slide-out-right {
        animation: slideOutRight 0.3s forwards;

        > * {
          overflow: hidden;
        }
      }

      &.slide-in-left {
        animation: slideInLeft 0.3s forwards;

        > * {
          overflow: hidden;
        }
      }

      &.slide-out-left {
        animation: slideOutLeft 0.3s forwards;

        > * {
          overflow: hidden;
        }
      }

      @keyframes slideInRight {
        from {
          transform: translate3d(100%, 0, 0);
        }
        to {
          transform: translate3d(0, 0, 0);
        }
      }

      @keyframes slideOutRight {
        from {
          transform: translate3d(0, 0, 0);
        }
        to {
          transform: translate3d(100%, 0, 0);
        }
      }

      @keyframes slideInLeft {
        from {
          transform: translate3d(-100%, 0, 0);
        }
        to {
          transform: translate3d(0, 0, 0);
        }
      }

      @keyframes slideOutLeft {
        from {
          transform: translate3d(0, 0, 0);
        }
        to {
          transform: translate3d(-100%, 0, 0);
        }
      }
    }

    // levels styling
    .main-items > .main-item,
    .left-content > .menu-items > .menu-item,
    .right-content {
      display: none;
    }

    .main-items > .main-item.mobile-active-path,
    .left-content > .menu-items > .menu-item.mobile-active-path {
      display: block;

      > .main-item-link,
      > .item-link {
        display: block;
      }
    }

    .main-items.mobile-active > .main-item {
      display: block;
    }

    .left-content.mobile-active > .menu-items > .menu-item {
      display: block;
    }

    .right-content.mobile-active {
      display: block;
    }

    .back-to-mainnav > .main-item-link,
    .mobile-back-link > .main-item-link {
      border-bottom: none;
      margin: 0 0 ($spacer * 1.5);
      padding: 0;
    }

    .mobile-active-path > .main-item-link,
    .mobile-active-path > .item-link {
      > .forward-caret-icon {
        display: none;
      }
    }

    .main-item-link,
    .item-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: $spacer * 0.5 0;
      border-bottom: map-get($borders, "base");
      color: $body-color;
      text-decoration: none;
    }

    .main-item.mobile-active-path:not(.mobile-back-link):not(.back-to-mainnav) > .main-item-link,
    .menu-item.mobile-active-path > .item-link {
      font-weight: $font-weight-bold;
    }

    .content-group {
      .group-title {
        margin-top: $spacer * 1.8 * 0.5;
        margin-bottom: $spacer * 1.8 * 0.5;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        color: $dark;

        &.default-group {
          display: none;
        }
      }
    }

    .main-item-metanavigation {
      &:before {
        display: block;
        content: "";
        margin: -1px ($grid-gutter-width * 0.5 * -1) 0;
        border-bottom: map-get($borders, "bold");
      }
    }

    .addon-image,
    .close-button,
    .ignore-mobile {
      display: none !important;
    }
  }

  @include media-breakpoint-up($desktop-breakpoint) {
    display: flex;
    justify-content: center;
    height: 2.2rem;
    transition: height 0.3s ease-in-out;
    overflow: hidden;

    .main-items {
      display: flex;
      justify-content: center;
      align-items: stretch;
      margin-bottom: $spacer * 0.5;

      .main-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;

        .main-item-link {
          display: block;
          text-decoration: none;
          margin-top: 0;
          margin-bottom: 0;
          padding: $spacer * 0.1 $spacer * 0.75;
          color: $body-color;
          line-height: inherit;
        }

        &.active,
        &:hover {
          .main-item-link {
            margin-left: 0;
            border-left: 0 none;
            margin-bottom: nth(set-nth(map-get($borders, "header"), 3, $primary), 1) * -1;
            border-bottom: set-nth(map-get($borders, "header"), 3, $primary);
            font-weight: $font-weight-medium;
          }
        }

        .mega-dropdown {
          display: none;

          padding: $spacer * 1.5 0;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          justify-content: space-between;
          align-items: stretch;
          max-height: 60vh;
          margin-top: $spacer * 0.5 * -1;
          flex-direction: row;

          .left-content {
            width: 25%;

            border-right: map-get($borders, "base");
            margin-right: -10px;
            padding-right: 10px;
          }

          .right-content {
            display: none;

            position: absolute;
            width: 75%;
            height: 100%;
            top: 0;
            right: 0;
            padding: $grid-gutter-width * 0.5 $grid-gutter-width * 0.5 0;

            .content-group {
              .group-title {
                margin-top: $spacer * 1.8 * 0.5;
                margin-bottom: 0;
                padding: 0 $spacer * 0.75;
                font-size: $font-size-sm;
                font-weight: $font-weight-bold;
                color: $dark;
              }
            }

            .addon-image {
              display: inline-block;
              text-align: right;

              a {
                display: block;
                font-weight: $font-weight-medium;

                span {
                  display: block;
                  margin-top: $spacer * 0.25;
                }
              }
            }

            .close-button {
              position: absolute;
              top: $spacer * 1.5;
              right: 0;
              padding: $spacer * 0.5;
              border: map-get($borders, "base");
              border-radius: 50%;
              font-size: $spacer * 0.75;
              line-height: 1;
            }
          }

          .default-content {
            display: block;
            position: relative;
            padding-top: 0;

            .close-button {
              top: 0;
            }
          }

          .menu-items {
            margin-left: -10px;
            padding-left: 10px;

            .menu-item {
              padding-top: $spacer * 1.8 * 0.25;
              padding-bottom: $spacer * 1.8 * 0.25;
              margin-right: -11px;

              .item-link {
                display: block;
                margin-top: 0;
                margin-bottom: 0;
                padding: 0 $spacer * 0.75;
                color: $body-color;
                line-height: 1.1;
                text-decoration: none;
              }

              &:first-child {
                padding-top: $spacer * 1.8 * 0.5;
              }
              &:last-child {
                padding-bottom: $spacer * 1.8 * 0.5;
              }

              &.active,
              &:hover {
                > .item-link {
                  margin-left: nth(set-nth(map-get($borders, "header"), 3, $primary), 1) * -1;
                  border-left: set-nth(map-get($borders, "header"), 3, $primary);
                  font-weight: $font-weight-medium;
                }

                &:hover {
                  .right-content {
                    display: block;
                  }
                }
              }
            }
          }

          &:before {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: -1;
            width: 200vw;
            background-color: $beige;
            border-top: map-get($borders, "header");
            border-bottom: map-get($borders, "header");
            box-shadow: inset map-get($shadows, "header");
            transform: translate3d(-50%, 0, 0);
          }
        }

        &:hover {
          > .mega-dropdown {
            display: flex;
          }
        }
      }
    }

    .hide-header-nav & {
      height: 0;

      .mega-dropdown {
        display: none !important;
      }
    }
  }
}
