@each $theme, $value in $theme-colors {
  $btn-bg: $value;
  $btn-border: $btn-bg;
  $btn-bg-hover: darken($value, $btn-hover-darken-percentage);
  $btn-border-hover: $btn-bg-hover;
  $btn-bg-active: darken($value, $btn-active-darken-percentage);
  $btn-border-active: $btn-bg-active;

  .btn-#{$theme} {
    @include button-variant($btn-bg, $btn-border, $btn-bg-hover, $btn-border-hover, $btn-bg-active, $btn-border-active);
    font-weight: $font-weight-bold;
  }

  $btn-outline-color: $value;
  $btn-outline-color-hover: color-yiq($value);
  $btn-outline-bg-active: $value;
  $btn-outline-border-active: $btn-outline-bg-active;

  $btn-outline-bg-active-overwrite: transparent;
  $btn-outline-border-active-overwrite: $black;

  .btn-outline-#{$theme} {
    @include button-outline-variant($btn-outline-color, $btn-outline-color-hover, $btn-outline-bg-active, $btn-outline-border-active);
    color: inherit;

    &.disabled,
    &:disabled {
      color: inherit;
    }

    // custom overwrite
    &.control {
      &:not(:disabled):not(.disabled).active {
        border-color: $btn-outline-border-active-overwrite;
        z-index: 2;

        &:not(:hover):not(:focus):not(.focus) {
          background-color: $btn-outline-bg-active-overwrite;
        }
      }
    }
  }
}

.btn-xs,
.btn-group-xs > .btn,
.form-control-xs .btn {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, $btn-border-radius-xs);
}

.btn-shapeless,
.btn-group-shapeless > .btn {
  @include button-variant(color-yiq($body-color), color-yiq($body-color));
  font-weight: $font-weight-bold;

  padding: 0 !important;
  background-color: transparent !important;
  border: 0 none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
