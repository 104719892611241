$badge-list-font-size: $badge-font-size !default;
$badge-list-badge-margin: $spacer * 0.1 !default;

.badge-list {
  margin: $badge-list-badge-margin * -1;
  font-size: $badge-list-font-size;

  .badge {
    margin: $badge-list-badge-margin;
    font-size: inherit;
  }
}
