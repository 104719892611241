
// mobile
$inspirationfilters-padding-y: $component-padding-y !default;
$inspirationfilters-padding-x: 0 !default;
$inspirationfilters-bg: $gray-100 !default;

$inspirationfilters-text-align: center !default;

$inspirationfilters-list-gap: $component-padding-y !default;
$inspirationfilters-list-padding-y: 0 !default;
$inspirationfilters-list-padding-x: $grid-gutter-width * 0.5 !default;

$inspirationfilters-list-filter-gutter: $grid-gutter-width * 0.5 !default;

$inspirationfilters-list-filter-image-size: 100px !default;
$inspirationfilters-list-filter-image-active-border: 0.2rem solid $primary !default;
$inspirationfilters-list-filter-image-active-border-offset: (0.35rem + nth($inspirationfilters-list-filter-image-active-border, 1)) * -1 !default;

$inspirationfilters-list-filter-text-gap: $spacer !default;
$inspirationfilters-list-filter-text-font-size: inherit !default;
$inspirationfilters-list-filter-text-font-weight: $font-weight-base !default;
$inspirationfilters-list-filter-text-color: $brown !default;
$inspirationfilters-list-filter-text-align: center !default;
$inspirationfilters-list-filter-text-active-font-weight: $font-weight-medium !default;
$inspirationfilters-list-filter-text-active-color: nth($inspirationfilters-list-filter-image-active-border, 3) !default;

// desktop
$inspirationfilters-breakpoint: $desktop-breakpoint !default;

$inspirationfilters-desktop-padding-y: $component-desktop-padding-y !default;
$inspirationfilters-desktop-padding-x: 0 !default;
$inspirationfilters-desktop-bg: $inspirationfilters-bg !default;

$inspirationfilters-desktop-text-align: $inspirationfilters-text-align !default;

$inspirationfilters-desktop-list-gap: $component-desktop-padding-y !default;
$inspirationfilters-desktop-list-padding-y: 0 !default;
$inspirationfilters-desktop-list-padding-x: $grid-gutter-width * 0.5 !default;

$inspirationfilters-desktop-list-filter-gutter: $grid-gutter-width !default;

$inspirationfilters-desktop-list-filter-image-size: $inspirationfilters-list-filter-image-size !default;

$inspirationfilters-desktop-list-filter-text-gap: $inspirationfilters-list-filter-text-gap !default;
$inspirationfilters-desktop-list-filter-text-font-size: $font-size-lg !default;

.inspirationfilters {
  @include fullwidth-bg();
  padding: $inspirationfilters-padding-y $inspirationfilters-padding-x;
  background-color: $inspirationfilters-bg;

  .inspirationfilters-text {
    @include indented-textelements();
    text-align: $inspirationfilters-text-align;
  }

  .inspirationfilters-filters {
    position: relative;
    left: 50%;
    margin-top: $inspirationfilters-list-gap;
    width: 100vw;
    transform: translate3d(-50%, 0, 0);
    text-align: center;
    cursor: grab;

    .filters-list {
      @include list-unstyled();
      justify-content: space-around;
      align-items: flex-start;
      flex-flow: wrap;
      margin: 0;
      padding: 0;
      display: block;
      overflow-x: auto;

      > li {
        padding-left: $inspirationfilters-list-filter-gutter * 0.5;
        padding-right: $inspirationfilters-list-filter-gutter * 0.5;
        display: inline-block;

        .filter {
          display: block;
          margin: 1rem 0;
          width: $inspirationfilters-list-filter-image-size;
          color: inherit;
          text-decoration: none;
          cursor: inherit;

          .filter-image {
            display: block;
            width: $inspirationfilters-list-filter-image-size;
            height: $inspirationfilters-list-filter-image-size;
            background: no-repeat center center;
            background-size: cover;
            border-radius: 50%;
            cursor: pointer;

            span {
              @include sr-only();
            }
          }

          .filter-text {
            display: block;
            margin-top: $inspirationfilters-list-filter-text-gap;
            font-size: $inspirationfilters-list-filter-text-font-size;
            font-weight: $inspirationfilters-list-filter-text-font-weight;
            color: $inspirationfilters-list-filter-text-color;
            text-decoration: none;
            text-align: $inspirationfilters-list-filter-text-align;
            cursor: pointer;
          }

          &.active {
            font-weight: $inspirationfilters-list-filter-text-active-font-weight;
            color: $inspirationfilters-list-filter-text-active-color;

            .filter-image {
              position: relative;

              &:before {
                display: block;
                content: '';
                position: absolute;
                inset: $inspirationfilters-list-filter-image-active-border-offset;
                border: $inspirationfilters-list-filter-image-active-border;
                border-radius: inherit;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up($inspirationfilters-breakpoint) {
    padding: $inspirationfilters-desktop-padding-y $inspirationfilters-desktop-padding-x;
    background-color: $inspirationfilters-desktop-bg;

    .inspirationfilters-text {
      text-align: $inspirationfilters-desktop-text-align;
    }

    .inspirationfilters-filters {
      margin-top: $inspirationfilters-desktop-list-gap;

      .filters-list {
        display: inline-flex;
        justify-content: flex-start;
        flex-flow: unset;
        margin: 0 ($inspirationfilters-desktop-list-filter-gutter * -0.5);
        padding: 0.35rem 0 $inspirationfilters-desktop-list-padding-y $inspirationfilters-desktop-list-padding-x;

        > li {
          padding-left: $inspirationfilters-desktop-list-filter-gutter * 0.5;
          padding-right: $inspirationfilters-desktop-list-filter-gutter * 0.5;
          display:block;

          .filter {
            width: $inspirationfilters-desktop-list-filter-image-size;
            margin: 1rem (nth($inspirationfilters-list-filter-image-active-border, 1) + $inspirationfilters-list-filter-image-active-border-offset) 0;

            .filter-image {
              width: $inspirationfilters-desktop-list-filter-image-size;
              height: $inspirationfilters-desktop-list-filter-image-size;
            }

            .filter-text {
              margin-top: $inspirationfilters-desktop-list-filter-text-gap;
              font-size: $inspirationfilters-desktop-list-filter-text-font-size;
            }
          }
        }
      }
    }
  }
}