.badge {
  @each $key, $value in $theme-colors {
    &.badge-outline-#{$key} {
      border: 0.1em solid $value;
    }
  }

  &.badge-lg {
    padding: ($badge-padding-y * 1.5) ($badge-padding-x * 3);
  }
}
