
// mobile
$carouselslidecontent-padding-y: $spacer * 2 !default;
$carouselslidecontent-padding-x: $grid-gutter-width * 0.5 !default;
$carouselslidecontent-max-width: 100% !default;
$carouselslidecontent-color: $white !default;
$carouselslidecontent-text-align: center !default;
$carouselslidecontent-text-shadow: map-get($shadows, 'carousel-text') !default;

// desktop
$carouselslidecontent-breakpoint: $desktop-breakpoint !default;

$carouselslidecontent-desktop-padding-y: $spacer * 5 !default;
$carouselslidecontent-desktop-padding-x: $carouselslidecontent-padding-x !default;
$carouselslidecontent-desktop-max-width: 50% !default;
$carouselslidecontent-desktop-color: $carouselslidecontent-color !default;
$carouselslidecontent-desktop-text-align: left !default;
$carouselslidecontent-desktop-text-shadow: $carouselslidecontent-text-shadow !default;

.carousel {
  .slick-slide {
    .slide-content {
      @include make-container();
      @include make-container-max-widths();

      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 0;
      z-index: 2;

      > div {
        padding: $carouselslidecontent-padding-y $carouselslidecontent-padding-x;
        color: $carouselslidecontent-color;
        max-width: $carouselslidecontent-max-width;
        text-align: $carouselslidecontent-text-align;

        *:not(.btn):not(.btn *) {
          color: inherit;
          text-shadow: $carouselslidecontent-text-shadow;
        }
      }
    }
  }

  @include media-breakpoint-up($carouselslidecontent-breakpoint) {
    .slick-slide {
      .slide-content {
        > div {
          padding: $carouselslidecontent-desktop-padding-y $carouselslidecontent-desktop-padding-x;
          color: $carouselslidecontent-color;
          max-width: $carouselslidecontent-desktop-max-width;
          text-align: $carouselslidecontent-desktop-text-align;

          *:not(.btn):not(.btn *) {
            text-shadow: $carouselslidecontent-desktop-text-shadow;
          }
        }
      }
    }
  }
}