$table-thead-th-font-size: $font-size-sm !default;
$table-thead-th-font-weight: $table-th-font-weight !default;
$table-thead-th-color: $gray-600 !default;

$table-tbody-th-font-size: inherit !default;
$table-tbody-th-font-weight: $font-weight-bold !default;
$table-tbody-th-color: inherit !default;

$table-stacked-data-label-font-size: $table-thead-th-font-size !default;
$table-stacked-data-label-font-weight: $table-thead-th-font-weight !default;
$table-stacked-data-label-color: $table-thead-th-color !default;
$table-stacked-data-label-text-align: left !default;

.table {
  thead {
    th {
      border-top: 0 none;
      font-size: $table-thead-th-font-size;
      font-weight: $table-thead-th-font-weight;
      color: $table-thead-th-color;
    }
  }

  tbody {
    th {
      font-size: $table-tbody-th-font-size;
      font-weight: $table-tbody-th-font-weight;
      color: $table-tbody-th-color;
    }
  }

  &.b-table {
    &.b-table-stacked {
      > tbody {
        > tr {
          > td[data-label] {
            display: flex;
            align-items: center;

            &:before {
              float: none;
              font-size: $table-stacked-data-label-font-size;
              font-weight: $table-stacked-data-label-font-weight;
              color: $table-stacked-data-label-color;
              text-align: $table-stacked-data-label-text-align;
            }
          }
        }
      }

      &.table-borderless {
        tr {
          + tr {
            padding-top: $table-cell-padding * 2;
          }
        }

        th, td {
          padding: 0;
        }

        &.table-sm {
          tr {
            + tr {
              padding-top: $table-cell-padding-sm * 2;
            }
          }
        }
      }
    }
  }
}
