// mobile
$footer-padding-y: $component-padding-y !default;
$footer-padding-x: 0 !default;
$footer-bg: $brownDark !default;
$footer-color: $beige !default;

$footer-link-color: inherit !default;
$footer-link-hover-opacity: 0.6 !default;

// desktop
$footer-breakpoint: $desktop-breakpoint !default;

$footer-desktop-padding-y: $component-desktop-padding-y !default;
$footer-desktop-padding-x: 0 !default;

// identityserver overwrites
@if $envname == 'identityserver' {
  // desktop
  $footer-desktop-padding-y: $spacer;
}

#app {
  > footer {
    .footer  {
      padding: $footer-padding-y $footer-padding-x;
      background-color: $footer-bg;
      color: $footer-color;

      a:not(.btn) {
        color: $footer-link-color;

        &:hover {
          opacity: $footer-link-hover-opacity;
          text-decoration: none;
        }
      }
    }

    @include media-breakpoint-up($footer-breakpoint) {
      .footer {
        padding: $footer-desktop-padding-y $footer-desktop-padding-x;
      }
    }
  }
}
