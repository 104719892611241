$documentlist-item-gap: $spacer * 0.5;
$documentlist-item-icon-gap: $spacer * 0.5;
$documentlist-item-link-gap: $spacer;

.document-list {
  @include list-unstyled();

  li {
    position: relative;
    margin-bottom: $documentlist-item-gap;
    padding-left: calc(1em + $documentlist-item-icon-gap);

    &:last-child {
      margin-bottom: 0;
    }

    .bi {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: $line-height-base + em;
    }

    .document-link {
      display: inline-block;
      margin-right: $documentlist-item-link-gap;
    }

    .document-size {
      display: inline-block;
    }
  }
}
