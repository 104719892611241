// mobile
$gridshifted-row-col-gap: $grid-gutter-width !default;
$gridshifted-row-col-shift: 0 !default;

// desktop
$gridshifted-breakpoint: $desktop-breakpoint !default;

$gridshifted-desktop-row-col-gap: $grid-gutter-width * 2.5 !default;
$gridshifted-desktop-row-col-shift: $gridshifted-desktop-row-col-gap * 0.75 !default;

.grid-shifted {
  > .row {
    margin-top: $gridshifted-row-col-gap * -0.5;
    margin-bottom: $gridshifted-row-col-gap * -0.5;
    padding-bottom: $gridshifted-row-col-shift;

    > .col {
      padding-top: $gridshifted-row-col-gap * 0.5;
      padding-bottom: $gridshifted-row-col-gap * 0.5;

      &:nth-child(2n) {
        position: relative;
        top: $gridshifted-row-col-shift;
      }
    }
  }

  @include media-breakpoint-up($gridshifted-breakpoint) {
    > .row {
      margin-top: $gridshifted-desktop-row-col-gap * -0.5;
      margin-bottom: $gridshifted-desktop-row-col-gap * -0.5;
      padding-bottom: $gridshifted-desktop-row-col-shift;

      > .col {
        padding-top: $gridshifted-desktop-row-col-gap * 0.5;
        padding-bottom: $gridshifted-desktop-row-col-gap * 0.5;

        &:nth-child(2n) {
          position: relative;
          top: $gridshifted-desktop-row-col-shift;
        }
      }
    }
  }
}