
// mobile
$product-section-padding-y: $spacer * 1.2 !default;
$product-section-border: map-get($borders, 'bold') !default;

$product-title-links-padding-y: $spacer * 0.25 !default;
$product-title-links-padding-x: $spacer * 2 !default;

$product-description-eyecatcher-gap: $spacer !default;

$product-configuration-fieldset-gap: $spacer !default;
$product-configuration-fieldset-item-gap-y: $product-configuration-fieldset-gap !default;
$product-configuration-fieldset-item-gap-x: $spacer * 2 !default;

$product-informations-gap: 0 !default;

$product-actions-gap: $spacer !default;
$product-actions-buttons-gap: $form-grid-gutter-width !default;
$product-actions-sharing-gap: $spacer !default;
$product-actions-sharing-button-color: $dark !default;

// desktop
$product-breakpoint: $desktop-breakpoint !default;

$product-desktop-section-padding-y: $product-section-padding-y !default;
$product-desktop-section-border: $product-section-border !default;

$product-desktop-title-links-padding-y: $product-title-links-padding-y !default;
$product-desktop-title-links-padding-x: $product-title-links-padding-x !default;

$product-desktop-description-eyecatcher-gap: $product-description-eyecatcher-gap !default;

$product-desktop-configuration-fieldset-gap: $product-configuration-fieldset-gap !default;
$product-desktop-configuration-fieldset-item-gap-y: $product-configuration-fieldset-item-gap-y !default;
$product-desktop-configuration-fieldset-item-gap-x: $product-configuration-fieldset-item-gap-x !default;

$product-desktop-informations-gap: $product-informations-gap !default;

$product-desktop-actions-gap: $product-actions-gap !default;
$product-desktop-actions-buttons-gap: $product-actions-buttons-gap !default;
$product-desktop-actions-sharing-gap: $product-actions-sharing-gap !default;
$product-desktop-actions-sharing-button-color: $product-actions-sharing-button-color !default;

.product {
  .product-header {
    .header-section {
      margin-top: $product-section-padding-y;
      padding-top: $product-section-padding-y;
      border-top: $product-section-border;
      &:empty {
        display: none;
      }

      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: 0 none;
      }

      + header,
      + section {
        border-top: $product-section-border;
      }
    }

    .product-title {
      h2,
      h3,
      h4,
      h5,
      h6,
      .h2,
      .h3,
      .h4,
      .h5,
      .h6 {
        text-transform: uppercase;
      }

      .lead {
        color: $black;
      }

      .title-links {
        display: flex;
        flex-wrap: wrap;
        margin: ($product-title-links-padding-y * -0.5) ($product-title-links-padding-x * -0.5);

        a {
          display: block;
          padding: ($product-title-links-padding-y * 0.5) ($product-title-links-padding-x * 0.5);
        }
      }
    }

    .product-description {
      position: relative;

      .eyecatcher-stack {
        position: absolute;
        top: 0;
        right: 0;
        margin: $product-description-eyecatcher-gap;
        z-index: 2;
      }

      .description-text {
        @include sr-only();
      }
    }

    .product-configurations {
      fieldset {
        display: flex;
        flex-wrap: wrap;
        margin: ($product-configuration-fieldset-item-gap-y * -0.5) ($product-configuration-fieldset-item-gap-x * -0.5);

        + fieldset {
          padding-top: $product-configuration-fieldset-gap;
        }

        > * {
          padding: ($product-configuration-fieldset-item-gap-y * 0.5) ($product-configuration-fieldset-item-gap-x * 0.5);
        }
      }
    }

    .product-informations {
      margin-top: $product-informations-gap;
    }

    .product-actions {
      margin-top: $product-actions-gap;

      .wishlistbutton,
      .setconfigurationbutton {
        display: block;
        width: 100%;
        margin-top: $product-actions-buttons-gap;
      }

      .shareing-is-caring {
        margin-top: $product-actions-sharing-gap;

        .share-button {
          padding: 0;
          color: $product-actions-sharing-button-color;
          text-decoration: none;
        }

        .social-list {
          justify-content: flex-start;
        }
      }
    }
  }

  @include media-breakpoint-up($product-breakpoint) {
    .product-header {
      .header-section {
        margin-top: $product-desktop-section-padding-y;
        padding-top: $product-desktop-section-padding-y;
        border-top: $product-desktop-section-border;

        &:first-child {
          margin-top: 0;
          padding-top: 0;
          border-top: 0 none;
        }

        + header,
        + section {
          border-top: $product-desktop-section-border;
        }
      }

      .product-title {
        .title-links {
          margin: ($product-desktop-title-links-padding-y * -0.5) ($product-desktop-title-links-padding-x * -0.5);

          a {
            padding: ($product-desktop-title-links-padding-y * 0.5) ($product-desktop-title-links-padding-x * 0.5);
          }
        }
      }

      .product-description {
        .eyecatcher-stack {
          margin: $product-desktop-description-eyecatcher-gap;
        }

        .description-text {
          @include reset-sr-only();
        }
      }

      .product-configurations {
        fieldset {
          margin: ($product-desktop-configuration-fieldset-item-gap-y * -0.5) ($product-desktop-configuration-fieldset-item-gap-x * -0.5);

          + fieldset {
            padding-top: $product-desktop-configuration-fieldset-gap;
          }

          > * {
            padding: ($product-desktop-configuration-fieldset-item-gap-y * 0.5) ($product-desktop-configuration-fieldset-item-gap-x * 0.5);
          }
        }
      }

      .product-informations {
        margin-top: $product-desktop-informations-gap;
      }

      .product-actions {
        margin-top: $product-desktop-actions-gap;

        .wishlistbutton,
        .setconfigurationbutton {
          margin-top: $product-desktop-actions-buttons-gap;
        }

        .shareing-is-caring {
          margin-top: $product-desktop-actions-sharing-gap;

          .share-button {
            color: $product-desktop-actions-sharing-button-color;
          }
        }
      }
    }
  }
}
