.product-teaser-promo-container {
  position: relative;
  height: 100%;

  @include media-breakpoint-down(md) {
    padding-top: 10px !important; // overwrite padding-top from product-teaser
    padding-bottom: 10px;
    border-bottom: $card-border-width solid $card-border-color;

    .view-stacked & {
      padding-top: 0 !important; // overwrite padding-top from product-teaser
    }
  }

  .product-teaser-promo {
    position: relative;
    height: 100%;

    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;

    border-radius: 3px;

    padding: $grid-gutter-width * 0.5;

    @each $key, $value in $pastel-colors {
      &.bg-#{$key} {
        background-color: $value;
      }
    }

    &.text-shadow-light {
      .lead-text,
      .header-text,
      .content-text {
        text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
      }
    }

    &.text-shadow-dark {
      .lead-text,
      .header-text,
      .content-text {
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
      }
    }

    .lead-text {
      font-size: $h6-font-size;
    }

    .header-text {
      font-size: $h5-font-size;
    }

    .content-text {
      flex-grow: 1;
      min-height: 5rem;
    }

    .btn {
      margin: 0 auto;
    }
  }
}
